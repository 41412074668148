@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=BenchNine:wght@300;400;700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');


body {
  margin: 0;
   font-family: "Source Sans 3", sans-serif;
   font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  scroll-behavior: smooth;
  color: #2a2a2a;
  overflow-x: hidden;

}

.text-1 {
    font-family: "Montserrat", sans-serif;

}

.text-2 {
    font-family: "Source Sans 3", sans-serif;
}

.text-3 {
    font-family: "Varela Round", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.title {
    font-family: "Montserrat", sans-serif;
    font-size: 62px;
    line-height: 1;
}

.title2 {
    font-family: "Montserrat", sans-serif;

    font-size: 44px;
    line-height: 1;
}

.description {
    font-family: "Source Sans 3", sans-serif;
    font-weight: 400;
    font-size: 1rem;
}


.color1 {
    color: #FFC912 ;
}
.color2 {
    color: #616160 ;
}
.color3 {
    color: #FFFFFF;
}
.color4 {
    color: #000000;
}
.color5 {
    color: #EFEADC;
}
.color6 {
    color: #2a2a2a;
}
.color7 {
    color: #f4c434;
}
.degrade1 {
    color: linear-gradient(to right, #2CD3E2, #F166AA);
}
.bordedg1 {
    border: 2px solid ;
    border-color: linear-gradient(to right, #2CD3E2, #F166AA);
}


.bg-color1 {
    background-color: #FFC912;
}
.bg-color2 {
    background: linear-gradient(to right, #ffc8126f, #ff8d126a);

}
.bg-color3 {
    background: linear-gradient(to right, #ffc81231, #ff8d1227);

}

.bg-color4 {
    background: linear-gradient(to bottom, #ffffff, #f5f5f548);
}

.bg-color5 {
    background: linear-gradient(to right, #ffe798, #ffd5a8);

}


.blur {
    backdrop-filter: blur(2px);
}

.shadowbg {
    box-shadow: 0 -7px 8px rgba(141, 141, 141, 0.052);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeIn 2s ease-in;
}


@keyframes slideInLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-in-left {
    animation: slideInLeft 1.5s ease-out;
}

@keyframes slideInRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-in-right {
    animation: slideInRight 1.5s ease-out;
}

@keyframes zoomIn {
    from {
        transform: scale(0.5);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.zoom-in {
    animation: zoomIn 1s ease-in-out;
}
